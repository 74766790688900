import api from '../api';

export const getPdvDataStorage = () =>{
  return JSON.parse(localStorage.getItem("pdvData"));
}

export const getPdvData = ({ email, coords }) => {
  return api.request({
    method: 'GET',
    url: `/usuario/usuarioestabelecimento?email=${email}&latitude=${coords.latitude}&longitude=${coords.longitude}`,
  });
};

export function getUserData(userId) {
  return api.request({
    method: 'GET',
    url: `/usuario/${userId}`,
  });
}
