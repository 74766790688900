import api from '../api';

export function getEstablishmentDiscount(establishmentId) {
  return api.request({
    method: 'GET',
    url: `/descontoestabelecimento/vigente/${establishmentId}`,
  });
}

export function getEstablishmentWallet({ establishmentId }) {
  return api.request({
    method: "GET",
    url: `/estabelecimento/carteira/${establishmentId}`
  })
}

export function getEstablishmentGeneral({ establishmentId }) {
  return api.request({
    method: "GET",
    url: `/estabelecimento/${establishmentId}`
  })
}
