import { combineReducers } from 'redux';

import authUser from './auth/reducer';
import card from './card/reducer';
import establishment from './establishment/reducer';
import extract from './extract/reducer';
import menu from './menu/reducer';
import region from './region/reducer';
import settings from './settings/reducer';
import townHall from './townHall/reducer';
import user from './user/reducer';

const reducers = combineReducers({
  menu,
  region,
  settings,
  authUser,
  user,
  townHall,
  establishment,
  card,
  extract,
});

export default reducers;
