export const Types = {
  GET_REGION_ASYNC: 'region/GET_REGION_ASYNC',
  GET_REGION_SUCCESS: 'region/GET_REGION_SUCCESS',
  GET_REGION_ERROR: 'region/GET_REGION_ERROR',
};

const initialState = {
  id: '',
  townhallId: '',
  name: '',
  coords: null,
  vacancyTypesList: [],
  loading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_REGION_ASYNC:
      return {
        ...state,
        loading: true,
      };

    case Types.GET_REGION_SUCCESS: {
      const {
        id,
        prefeituraId,
        nome,
        coordenadas,
        tiposVagaRegiaoPrefeitura,
      } = action.payload.region;
      return {
        ...state,
        loading: false,
        id,
        townhallId: prefeituraId,
        name: nome,
        coords: coordenadas,
        vacancyTypesList: tiposVagaRegiaoPrefeitura,
      };
    }

    case Types.GET_REGION_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
      };

    default:
      return state;
  }
}
