export default async function GetLocation() {
  const coords = await new Promise((resolve, reject) => {
    if (navigator && navigator.geolocation) {
      return navigator.geolocation.getCurrentPosition(
        (position) => {
          return resolve({ permission: true, coords: position.coords });
        },
        (permissionDenied) => resolve({ permission: false })
      );
    }
  });
  return coords;
}
