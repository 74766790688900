export const Types = {
  GET_ESTABLISHMENT: 'establishment/GET_ESTABLISHMENT',
  GET_ESTABLISHMENT_SUCCESS: 'establishment/GET_ESTABLISHMENT_SUCCESS',
  GET_ESTABLISHMENT_ERROR: 'establishment/GET_ESTABLISHMENT_ERROR',

  GET_ESTABLISHMENT_BALANCE: 'establishment/GET_ESTABLISHMENT_BALANCE',
  GET_ESTABLISHMENT_BALANCE_SUCCESS:
    'establishment/GET_ESTABLISHMENT_BALANCE_SUCCESS',
    GET_ESTABLISHMENT_HISTORY_SUCCESS:
    'establishment/GET_ESTABLISHMENT_HISTORY_SUCCESS',
  GET_ESTABLISHMENT_BALANCE_ERROR:
    'establishment/GET_ESTABLISHMENT_BALANCE_ERROR',

  GET_ESTABLISHMENT_DISCOUNT: 'GET_ESTABLISHMENT_DISCOUNT',
  GET_ESTABLISHMENT_DISCOUNT_SUCCESS: 'GET_ESTABLISHMENT_DISCOUNT_SUCCESS',
  GET_ESTABLISHMENT_DISCOUNT_ERROR: 'GET_ESTABLISHMENT_DISCOUNT_ERROR',

  GET_FULL_ESTABLISHMENT_DATA: 'establishment/GET_FULL_ESTABLISHMENT_DATA',
  GET_HISTORY_ESTABLISHMENT_DATA: 'establishment/GET_HISTORY_ESTABLISHMENT_DATA',
};

const initialState = {
  id: '',
  loading: false,
  companyName: '',
  tradingName: '',
  cnpj: '',
  discountPercentage: 0,
  balance: 0,
  totalAmount:0,
  totalBought:0,
  errors: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_ESTABLISHMENT:
      return {
        ...state,
        loading: true,
      };

    case Types.GET_ESTABLISHMENT_SUCCESS: {
      const {
        id,
        razaoSocial,
        nomeFantasia,
        cnpj,
      } = action.payload.establishment;
      return {
        ...state,
        loading: false,
        id,
        companyName: razaoSocial,
        tradingName: nomeFantasia,
        cnpj,
      };
    }

    case Types.GET_ESTABLISHMENT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case Types.GET_ESTABLISHMENT_BALANCE:
      return {
        ...state,
        loading: true,
      };

    case Types.GET_ESTABLISHMENT_BALANCE_SUCCESS: {
      return {
        ...state,
        loading: false,
        balance: action.payload.balance,
      };
    }

    case Types.GET_ESTABLISHMENT_HISTORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        totalAmount: action.payload.totalAmount,
        totalBought: action.payload.totalBought
      };
    }

    case Types.GET_ESTABLISHMENT_BALANCE_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
      };

    case Types.GET_ESTABLISHMENT_DISCOUNT:
      return {
        ...state,
        loading: true,
      };

    case Types.GET_ESTABLISHMENT_DISCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        discountPercentage: action.payload.discount,
      };

    case Types.GET_ESTABLISHMENT_DISCOUNT_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
      };

    case Types.GET_FULL_ESTABLISHMENT_DATA:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}
export function getEstablishmentBalance(establishmentId) {
  return {
    type: Types.GET_ESTABLISHMENT_BALANCE,
    payload: {
      establishmentId,
    },
  };
}

export function getEstablishmentDiscount(establishmentId) {
  return {
    type: Types.GET_ESTABLISHMENT_DISCOUNT,
    payload: {
      establishmentId,
    },
  };
}

export function getFullEstablishmentData() {
  return {
    type: Types.GET_FULL_ESTABLISHMENT_DATA,
  };
}

export function getHistoryEstablishmentData() {
  return {
    type: Types.GET_HISTORY_ESTABLISHMENT_DATA,
  };
}
