import { all, takeLatest, throttle } from 'redux-saga/effects';

import { Types as authTypes } from './auth/reducer';
import * as auth from './auth/saga';
import { Types as cardTypes } from './card/reducer';
import * as card from './card/saga';
import { Types as establishmentTypes } from './establishment/reducer';
import * as establishment from './establishment/saga';
import { Types as extractTypes } from './extract/reducer';
import * as extract from './extract/saga';

function* watchAuthAsync() {
  yield takeLatest(authTypes.LOGIN_USER, auth.loginAsync);
  yield takeLatest(authTypes.LOGOUT_USER, auth.logoutAsync);
}

function* watchEstablishmentAsync() {
  yield takeLatest(
    establishmentTypes.GET_FULL_ESTABLISHMENT_DATA,
    establishment.getFullEstablishmentData
  );
  yield takeLatest(
    establishmentTypes.GET_HISTORY_ESTABLISHMENT_DATA,
    establishment.getHistoryEstablishmentData
  );
}

function* watchCardAsync() {
  yield takeLatest(cardTypes.GET_CARDS, card.getCards);
  yield takeLatest(cardTypes.ADD_CARD, card.addCard);
  yield takeLatest(cardTypes.DELETE_CARD, card.deleteCard);
}

function* watchExtractAsync() {
  yield takeLatest(extractTypes.GET_FULL_EXTRACT, extract.getFullExtract);
}

export default function* rootSaga(getState) {
  yield all([
    watchAuthAsync(),
    watchEstablishmentAsync(),
    watchCardAsync(),
    watchExtractAsync(),
  ]);
}
