export const Types = {
  GET_TOWNHALL_LAST_CAD_ASYNC: 'townhall/GET_TOWNHALL_LAST_CAD_ASYNC',
  GET_TOWNHALL_LAST_CAD_SUCCESS: 'townhall/GET_TOWNHALL_LAST_CAD_SUCCESS',
  GET_TOWNHALL_LAST_CAD_ERROR: 'townhall/GET_TOWNHALL_LAST_CAD_ERROR',
};

const initialState = {
  id: '',
  lastCadPrice: 0,
  loading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_TOWNHALL_LAST_CAD_ASYNC:
      return {
        ...state,
        loading: true,
      };

    case Types.GET_TOWNHALL_LAST_CAD_SUCCESS:
      return {
        ...state,
        loading: false,
        id: action.payload.lastCad.id,
        lastCadPrice: action.payload.lastCad.valorUnitario,
      };

    case Types.GET_TOWNHALL_LAST_CAD_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}

export function getTownhallLastPriceAsync(townhallId) {
  return {
    type: Types.GET_TOWNHALL_LAST_CAD_ASYNC,
    payload: {
      townhallId,
    },
  };
}
