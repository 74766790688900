export const Types = {
  GET_FULL_EXTRACT: 'extract/GET_FULL_EXTRACT',
  GET_FULL_EXTRACT_SUCCESS: 'extract/GET_FULL_EXTRACT_SUCCESS',
  GET_FULL_EXTRACT_ERROR: 'extract/GET_FULL_EXTRACT_ERROR',
};

const initialStates = {
  purchaseList: [],
  saleList: [],
  loading: false,
};

export default function reducer(state = initialStates, action) {
  switch (action.type) {
    case Types.GET_FULL_EXTRACT:
      return {
        ...state,
        loading: true,
      };

    case Types.GET_FULL_EXTRACT_SUCCESS: {
      const { saleList, purchaseList } = action.payload;
      return {
        ...state,
        saleList,
        purchaseList,
        loading: false,
      };
    }

    case Types.GET_FULL_EXTRACT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}

export function getFullExtract() {
  return {
    type: Types.GET_FULL_EXTRACT,
  };
}
