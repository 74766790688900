import { call, put } from 'redux-saga/effects';

import { Alert } from '../../helpers/Alert';
import formatError from '../../helpers/Errors';
import { login } from '../../services/requests/Auth';
import {getEstablishmentDiscount} from '../../services/requests/Establishment';
import { Types as authTypes } from './reducer';

export function* loginAsync({ payload }) {
  try {
    const response = yield call(login, {
      ...payload.userLoginData,
    });

    const data = response.data;

    localStorage.setItem('token', data?.access_token);
    localStorage.setItem('refreshToken', data?.['.refreshToken']);
    localStorage.setItem('userId', data?.['.id']);

    yield put({
      type: authTypes.LOGIN_USER_SUCCESS,
      payload: {
        accessToken: response.data?.access_token,
      },
    });

    const establishmentId = data?.carteiraEstabelecimento?.estabelecimentoId;

    const responseDiscount = yield call(getEstablishmentDiscount, establishmentId);

    const pdvData = {
      userId:data?.['.id'],
      establishmentId,
      townHallId: data?.regiaoPrefeitura?.prefeituraId,
      walletType: data?.carteiraEstabelecimento?.tipoCarteira,
      discountPercentage:responseDiscount.data.data.valorPorcentagem
    };

    localStorage.setItem('pdvData', JSON.stringify(pdvData));

    payload.history.push('/');
  } catch (error) {
    yield put({
      type: authTypes.LOGIN_USER_ERROR,
    });
    Alert({
      type: 'error',
      title: formatError(error),
    });
  }
}

export function* logoutAsync() {
  try {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('pdvData');

    yield put({
      type: authTypes.LOGOUT_USER_SUCCESS,
    });
  } catch (error) {
    console.log(error);
    Alert({
      type: 'error',
      title: 'Erro ao deslogar',
    });
  }
}
