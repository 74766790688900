import api from '../api';

export function getCreditCards({ userId }) {
  return api.request({
    url: `/cartaocreditousuario?UsuarioId=${userId}`,
    method: 'GET',
  });
}

export function addCreditCard({ data }) {
  return api.request({
    url: '/cartaocreditousuario',
    method: 'POST',
    data: {
      ...data,
    },
  });
}

export function deleteCreditCard({ cardId }) {
  api.request({
    url: `/cartaocreditousuario/${cardId}`,
    method: 'DELETE',
  });
}
