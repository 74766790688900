export const Types = {
  GET_CARDS: 'cards/GET_CARDS',
  GET_CARDS_SUCCESS: 'cards/GET_CARDS_SUCCESS',
  GET_CARDS_ERROR: 'cards/GET_CARDS_ERROR',

  ADD_CARD: 'cards/ADD_CARD',
  ADD_CARD_SUCCESS: 'cards/ADD_CARD_SUCCESS',
  ADD_CARD_ERROR: 'cards/ADD_CARD_ERROR',

  DELETE_CARD: 'cards/DELETE_CARD',
  DELETE_CARD_SUCCESS: 'cards/DELETE_CARD_SUCCESS',
  DELETE_CARD_ERROR: 'cards/DELETE_CARD_ERROR',
};

const initialState = {
  cardsList: [],
  loading: false,
  errors: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CARDS:
      return {
        ...state,
        loading: true,
      };

    case Types.GET_CARDS_SUCCESS:
      return {
        ...state,
        cardsList: action.payload.cardsList,
        loading: false,
      };

    case Types.GET_CARDS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case Types.ADD_CARD:
      return {
        ...state,
        loading: true,
      };

    case Types.ADD_CARD_SUCCESS: {
      const { card } = action.payload;
      delete card.usuario;

      const newArray = [...state.cardsList, card];

      return {
        ...state,
        cardsList: newArray,
        loading: false,
      };
    }

    case Types.ADD_CARD_ERROR:
      return {
        ...state,
        loading: false,
      };

    case Types.DELETE_CARD:
      return {
        ...state,
        loading: true,
      };

    case Types.DELETE_CARD_SUCCESS: {
      const { cardId } = action.payload;

      const newArray = state.cardsList.filter((card) => card.id !== cardId);
      return {
        ...state,
        cardsList: newArray,
        loading: false,
      };
    }

    case Types.DELETE_CARD_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}

export function getCards() {
  return {
    type: Types.GET_CARDS,
  };
}

export function addCard(cardData) {
  return {
    type: Types.ADD_CARD,
    payload: {
      cardData,
    },
  };
}

export function deleteCard(cardId) {
  return {
    type: Types.DELETE_CARD,
    payload: {
      cardId,
    },
  };
}
