export const Types = {
  LOGIN_USER: 'auth/LOGIN_USER',
  LOGIN_USER_SUCCESS: 'auth/LOGIN_USER_SUCCESS',
  LOGIN_USER_ERROR: 'auth/LOGIN_USER_ERROR',

  REGISTER_USER: 'auth/REGISTER_USER',
  REGISTER_USER_SUCCESS: 'auth/REGISTER_USER_SUCCESS',
  REGISTER_USER_ERROR: 'auth/REGISTER_USER_ERROR',

  FORGOT_PASSWORD: 'auth/FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS: 'auth/FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR: 'auth/FORGOT_PASSWORD_ERROR',

  RESET_PASSWORD: 'auth/RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS: 'auth/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR: 'auth/RESET_PASSWORD_ERROR',

  LOGOUT_USER: 'auth/LOGOUT_USER',
  LOGOUT_USER_SUCCESS: 'auth/LOGOUT_USER_SUCCESS',
  LOGOUT_USER_ERROR: 'auth/LOGOUT_USER_ERROR',
};

const INIT_STATE = {
  user: localStorage.getItem('token'),
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.LOGIN_USER:
      return { ...state, loading: true, error: '' };

    case Types.LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.accessToken,
        error: '',
      };

    case Types.LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        user: '',
      };

    case Types.FORGOT_PASSWORD:
      return { ...state, loading: true, error: '' };

    case Types.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotUserMail: action.payload,
        error: '',
      };

    case Types.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        forgotUserMail: '',
        error: action.payload.message,
      };

    case Types.RESET_PASSWORD:
      return { ...state, loading: true, error: '' };

    case Types.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        newPassword: action.payload,
        resetPasswordCode: '',
        error: '',
      };

    case Types.RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        newPassword: '',
        resetPasswordCode: '',
        error: action.payload.message,
      };

    case Types.REGISTER_USER:
      return { ...state, loading: true, error: '' };

    case Types.REGISTER_USER_SUCCESS:
      return { ...state, loading: false, user: action.payload.uid, error: '' };

    case Types.REGISTER_USER_ERROR:
      return {
        ...state,
        loading: false,
        user: '',
        error: action.payload.message,
      };

    case Types.LOGOUT_USER_SUCCESS:
      return { ...state, user: '' };

    default:
      return { ...state };
  }
};

export function login(requestData) {
  return {
    type: Types.LOGIN_USER,
    payload: {
      userLoginData: requestData.userData,
      history: requestData.history,
    },
  };
}
