import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const render = () => {
  import(`./assets/css/sass/themes/gogo.light.blue.scss`).then((x) => {
    require('./AppRenderer');
  });
};
render();
