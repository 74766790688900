import api from '../api';

export function getAllTownHalls({ townHall = '' } = '') {
  return api.request({
    method: 'GET',
    url: `/prefeitura?Cidade=${townHall}`,
  });
}

export function getLastCADTownhall({ townHallId }) {
  return api.request({
    method: 'GET',
    url: `/prefeitura/${townHallId}`,
  });
}

export const getTownHallById = ({id}) =>
api.request({
  url: `/prefeitura/${id}`,
  method: "GET"
});
