import api from '../api';

export function getExtractListPdv({
  establishmentId = '',
  regionId = '',
  initDate = '',
  endDate = '',
  size = '',
  page = '',
}) {
  return api.request({
    method: 'GET',
    url: `/historicoativacaocadestabelecimento?EstabelecimentoId=${establishmentId}&RegiaoPrefeituraId=${regionId}&DataInicio=${initDate}&DataTermino=${endDate}&PageSize=${size}&Page=${page}&OrderBy=DataAtivacao DESC`,
  });
}

export function getPurchaseListPdv({
  establishmentId = '',
  initDate = '',
  endDate = '',
  page = '',
  size = '',
}) {
  return api.request({
    method: 'GET',
    url: `/compracadestabelecimento?EstabelecimentoId=${establishmentId}&DataInicio=${initDate}&DataTermino=${endDate}&PageSize=${size}&Page=${page}&OrderBy=DataCompra DESC`,
  });
}
