import { call, put, select } from 'redux-saga/effects';

import { Alert } from '../../helpers/Alert';
import formatError from '../../helpers/Errors';
import getLocation from '../../helpers/GetLocation';
import { getEstablishmentDiscount, getEstablishmentGeneral } from '../../services/requests/Establishment';
import { getLastCADTownhall } from '../../services/requests/TownHall';
import { getUserData, getPdvData } from '../../services/requests/User';
import { Types as regionTypes } from '../region/reducer';
import { Types as townHallTypes } from '../townHall/reducer';
import { Types as userTypes } from '../user/reducer';
import { Types as establishmentTypes } from './reducer';

export function* getFullEstablishmentData() {
  try {
    const userId = localStorage.getItem('userId');
    const position = yield getLocation();
    const userDataResponse = yield call(getUserData, userId);

    const establishmentDataResponse = yield call(getPdvData, {
      email: userDataResponse.data?.data?.email,
      coords: position.coords,
    });

    const discountEstablishmentResponse = yield call(
      getEstablishmentDiscount,
      establishmentDataResponse.data?.data?.estabelecimento?.id
    );

    const townHallLastCadResponse = yield call(getLastCADTownhall, {
      townHallId:
        establishmentDataResponse.data?.data?.regiaoPrefeitura?.prefeituraId,
    });

    yield put({
      type: establishmentTypes.GET_ESTABLISHMENT_SUCCESS,
      payload: {
        establishment: establishmentDataResponse.data?.data?.estabelecimento,
      },
    });

    yield put({
      type: establishmentTypes.GET_ESTABLISHMENT_BALANCE_SUCCESS,
      payload: {
        balance:
          establishmentDataResponse.data?.data?.carteiraEstabelecimento?.saldo,

      },
    });

    yield put({
      type: establishmentTypes.GET_ESTABLISHMENT_DISCOUNT_SUCCESS,
      payload: {
        discount: discountEstablishmentResponse.data?.data?.valorPorcentagem,
      },
    });

    yield put({
      type: townHallTypes.GET_TOWNHALL_LAST_CAD_SUCCESS,
      payload: {
        lastCad: townHallLastCadResponse.data?.data,
      },
    });

    yield put({
      type: regionTypes.GET_REGION_SUCCESS,
      payload: {
        region: establishmentDataResponse.data?.data?.regiaoPrefeitura,
      },
    });

    yield put({
      type: userTypes.GET_USER_DATA_SUCCESS,
      payload: {
        id: establishmentDataResponse.data?.data?.id,
        documentNumber: establishmentDataResponse.data?.data?.numeroDocumento,
        email: establishmentDataResponse.data?.data?.email,
        name: establishmentDataResponse.data?.data?.nome,
        manager: establishmentDataResponse.data?.data?.gestor,
      },
    });
  } catch (error) {
    Alert({
      type: 'error',
      title: formatError(error),
    });
    yield put({
      type: establishmentTypes.GET_ESTABLISHMENT_ERROR,
    });
  }
}

export function* getHistoryEstablishmentData(){

  try {
    const pdvData = JSON.parse(localStorage.getItem('pdvData'));
    const establishmentId = pdvData.establishmentId;
    const response = yield call(getEstablishmentGeneral, {establishmentId});

    yield put({
      type: establishmentTypes.GET_ESTABLISHMENT_HISTORY_SUCCESS,
      payload: {
        totalAmount:response.data?.data?.totalQuantidadeCompra,
        totalBought:response.data?.data?.totalValorCompra,
      },
    });

  }catch(error){
    Alert({
      type: 'error',
      title: formatError(error),
    });
    yield put({
      type: establishmentTypes.GET_ESTABLISHMENT_ERROR,
    });

  }
}
