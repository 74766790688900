import axios from 'axios';
import { configureStore } from '../redux/store';

//oldUrl https://api.prefeituras.luby.com.br/api
const api = axios.create({
  baseURL: 'https://appzulapi.azurewebsites.net/api',
  // headers: { AppKey: "abcKey", AppPass: "abcPass" }
});

const refresh = (refreshData) =>
  api.request({
    method: 'POST',
    url: '/token/refresh',
    data: {
      ...refreshData,
    },
  });

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('token');
  const headers = { ...config.headers };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
    headers.Accept = 'application/json';
  }

  return { ...config, headers };
});

api.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      (error?.response?.status === 401 || error?.response?.status === 502) &&
      originalRequest === '/token/refresh'
    ) {
      localStorage.clear();

      configureStore().dispatch({
        type: 'auth/LOGOUT_USER',
      });

      return Promise.reject('Login expirado!');
    }
    if (error?.response?.status === 401 || error?.response?.status === 502) {
      if (!originalRequest._retry) {
        originalRequest._retry = true;
        const refreshToken = localStorage.getItem('refreshToken');
        const userId = localStorage.getItem('userId');

        try {
          const response = await refresh({
            usuarioId: userId,
            token: refreshToken,
          });
          localStorage.setItem('token', response.data.access_token);
          localStorage.setItem('refreshToken', response.data['.refreshToken']);

          return api(originalRequest);
        } catch (error) {
          localStorage.clear();

          configureStore().dispatch({
            type: 'auth/LOGOUT_USER',
          });
          return Promise.reject('Login expirado!');
        }
      }
    }
    return Promise.reject(error);
  }
);

export default api;
