import { call, put, select } from 'redux-saga/effects';

import { Alert } from '../../helpers/Alert';
import formatError from '../../helpers/Errors';
import {
  getExtractListPdv,
  getPurchaseListPdv,
} from '../../services/requests/Extract';
import { Types } from './reducer';

export function* getFullExtract({ payload }) {
  try {

    const pdvData = JSON.parse(localStorage.getItem('pdvData'));
    const establishmentId = pdvData.establishmentId;

    const { id: regionId } = yield select((state) => state.region);

    const saleResponse = yield call(getExtractListPdv, {
      establishmentId,
      regionId,
    });
    const purchaseResponse = yield call(getPurchaseListPdv, {
      establishmentId,
    });

    yield put({
      type: Types.GET_FULL_EXTRACT_SUCCESS,
      payload: {
        saleList: saleResponse.data?.data,
        purchaseList: purchaseResponse.data?.data,
      },
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: Types.GET_FULL_EXTRACT_ERROR,
    });
    Alert({
      type: 'error',
      title: formatError(error),
    });
  }
}
