import { put, call, select } from 'redux-saga/effects';

import { Alert } from '../../helpers/Alert';
import formatError from '../../helpers/Errors';
import {
  addCreditCard,
  deleteCreditCard,
  getCreditCards,
} from '../../services/requests/Card';
import { Types as cardTypes } from './reducer';

export function* getCards() {
  try {
    const userId = localStorage.getItem("userId");
    const response = yield call(getCreditCards, { userId });

    const cards = response.data?.data?.map((card) => ({
      id: card.id,
      userId: card.usuarioId,
      token: card.token,
      finalCardNumber: card.codigoFinalCartao,
      brand: card.bandeiraCartao,
      status: card.status,
    }));

    yield put({
      type: cardTypes.GET_CARDS_SUCCESS,
      payload: {
        cardsList: cards,
      },
    });
  } catch (error) {
    Alert({
      type: 'error',
      title: formatError(error),
    });
    yield put({
      type: cardTypes.GET_CARDS_ERROR,
    });
  }
}

export function* addCard({ payload }) {
  try {
    const request = {
      usuarioId: Number(payload.cardData.userId),
      mesVencimento: Number(payload.cardData.validateMonth),
      anoVencimento: Number(payload.cardData.validateYear),
      cvv: payload.cardData.cvv,
      nomeImpresso: payload.cardData.name,
      numero: payload.cardData.cardNumber,
      bandeiraCartao: Number(payload.cardData.cardBrand),
    };

    const response = yield call(addCreditCard, { data: request });
    yield put({
      type: cardTypes.ADD_CARD_SUCCESS,
      payload: {
        card: {
          id: response.data.data.id,
          userId: response.data.data.usuarioId,
          token: response.data.data.token,
          finalCardNumber: response.data.data.codigoFinalCartao,
          brand: response.data.data.bandeiraCartao,
          status: response.data.data.status,
        },
      },
    });
    Alert({
      type: 'success',
      title: 'Cartão adicionado com sucesso',
    }).then(() => payload.cardData.toggleModal());
  } catch (error) {
    Alert({
      type: 'error',
      title: formatError(error),
    });
    yield put({
      type: cardTypes.ADD_CARD_ERROR,
    });
  }
}

export function* deleteCard({ payload }) {
  try {
    yield call(deleteCreditCard, payload);

    yield put({
      type: cardTypes.DELETE_CARD_SUCCESS,
      payload: {
        cardId: payload.cardId,
      },
    });
  } catch (error) {
    Alert({
      type: 'error',
      title: formatError(error),
    });
    yield put({
      type: cardTypes.DELETE_CARD_ERROR,
    });
  }
}
