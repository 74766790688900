import api from '../api';

export function login(userLoginData) {
  return api.request({
    method: 'POST',
    url: '/tokenusuarioestabelecimento',
    data: {
      ...userLoginData,
    },
  });
}
